/* App.css */

:root {
  --primary-color: #ff8474;
  --secondary-color: #583d72;
  --highlight-color: #00f6ff;
  --background-color: #141416;
  --text-color: #fcfcfd;
  --border-color: #353945;
  --font-family: 'Orbitron', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient(rgba(20, 20, 22, 0.7), rgba(20, 20, 22, 0.7)),
    url('/public/burnout.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

input {
  font-family: var(--font-family);
  font-size: 1rem;
  padding: 8px 12px;
  background-color: rgba(35, 38, 47, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  margin-bottom: 1rem;
  outline: none;
  width: 100%;
  max-width: 300px;
}

button {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
  background-color: var(--highlight-color);
  border: none;
  border-radius: 4px;
  color: var(--background-color);
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

button:hover {
  background-color: var(--primary-color);
}

button:active {
  transform: scale(0.95);
}

.react-countdown {
  font-size: 5rem;
  font-weight: bold;
  text-shadow: 0 0 10px var(--highlight-color);
  margin-bottom: 2rem;
}
