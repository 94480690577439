.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    text-align: center;
    font-size: 0.9rem;
    padding: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  